@import "../../vendor/reset.min.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  box-sizing: border-box;
  user-select: none;
  text-size-adjust: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -ms-content-zooming: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  background-color: #000;
  color: #fff;
}

a, a:hover, a:active, a:visited {
  text-decoration: none;
  color: inherit;
}

.pano {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sceneList{
  position: absolute;
  height: 100%;
  width: 13%;
  background-color: rgba(255, 255, 255, 0.5);
  overflow-y: auto;
}

.sceneListElementTxt {
  color:black;
  // text-transform: uppercase;
  margin-top: 10px;
}

.sceneListElementImg {
  height: 60px;
  width: 130px;
  border-radius: 10px;
  box-shadow: 5px 5px rgba(103,115,131,0.8);
}

.sceneListElement {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.active {
  font-weight: bold;;
}


// .fullscreenToggle {
//   display: none;
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 40px;
//   height: 40px;
//   padding: 5px;
//   background-color: rgba(0, 51, 102,0.8);
// }

// .fullscreenToggle {
//   display: block;
// }

// .fullscreenToggle .icon {
//   position: absolute;
//   top: 5px;
//   right: 5px;
//   width: 30px;
//   height: 30px;
// }

// .fullscreenToggle .icon.on,
.autorotateToggle .icon.on {
  display: none;
}

// .fullscreenToggle .icon.off,
.autorotateToggle .icon.off {
  display: block;
}

// .fullscreenToggle.enabled .icon.on,
.autorotateToggle.enabled .icon.on  {
  display: block;
}

// .fullscreenToggle.enabled .icon.off,
.autorotateToggle.enabled .icon.off {
  display: none;
}

.autorotateToggle, .webLink {
  display: block;
  position: absolute;
  top: 0;
  right: 0px;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: rgba(0, 51, 102,0.8);
}

.webLink {
  right: 80px;
}

.autorotateToggle .icon,
.webLink .icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
}

/*-----------------------------------
DEO KOJIM SE STILIZUJE LINK HOTSPOT
------------------------------------*/

.link-hotspot {
  width: 40px;
  height: 40px;
  top: 19px;
  margin: -30px 0 0 -30px;
  opacity: 0.9;
  transition: opacity 0.2s;
}

.link-hotspot:hover {
  opacity: 1;
}

.link-hotspot-icon {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.link-hotspot-tooltip {
  position: absolute;
  left: 100%;
  top: 3px; 
  pointer-events: none;
  margin-left: 3px;
  font-size: 16px;
  max-width: 300px;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: rgba(0, 51, 102,0.8);
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transform: translateX(-8px);
  transition: transform 0.3s, opacity 0.3s;
}


.link-hotspot:hover .link-hotspot-tooltip {
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
}


/*-----------------------------------
DEO KOJIM SE STILIZUJE INFO HOTSPOT
------------------------------------*/
.info-hotspot {
  line-height: 18px;
  opacity: 0.9;
  -webkit-transition: opacity 0.2s 0.2s;
  transition: opacity 0.2s 0.2s;
}

.info-hotspot:hover {
  opacity: 1;
  transition: opacity 0.2s;
}

.info-hotspot.visible {
  opacity: 1;
}

.info-hotspot .info-hotspot-header {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: rgb(27, 75, 122);
  cursor: pointer;
 
}

.info-hotspot.visible .info-hotspot-header,
.info-hotspot.visible .info-hotspot-header:hover,
.info-hotspot .info-hotspot-header:hover {
  width: 260px;
  border-radius: 5px;
}

.info-hotspot.visible .info-hotspot-header,
.info-hotspot.visible .info-hotspot-header:hover {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.info-hotspot .info-hotspot-header,
.info-hotspot .info-hotspot-header:hover
.info-hotspot.visible .info-hotspot-header,
.info-hotspot.visible .info-hotspot-header:hover {
  transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
}


.info-hotspot .info-hotspot-icon-wrapper {
  width: 40px;
  height: 40px;
}

.info-hotspot .info-hotspot-icon {
  width: 90%;
  height: 90%;
  margin: 5%;
}

.info-hotspot .info-hotspot-title-wrapper {
  position: absolute;
  left: 40px;
  top: 0;
  width: 0;
  height: 40px;
  padding: 0;
  overflow: hidden;
  transition: width 0s 0.4s, padding 0s 0.4s;
}

.info-hotspot.visible .info-hotspot-title-wrapper,
.info-hotspot .info-hotspot-header:hover .info-hotspot-title-wrapper {
  width: 220px;
  padding: 0 5px;
  transition: width 0s 0.4s, padding 0s 0.4s;
}

.info-hotspot .info-hotspot-title-wrapper:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.info-hotspot .info-hotspot-title {
  font-weight: bold;
  letter-spacing: 2.5px;
  display: inline-block;
  vertical-align: middle;
}

.info-hotspot .info-hotspot-close-wrapper {
  position: absolute;
  left: 260px;
  top: 0;
  height: 40px;
  width: 40px;
  border-top-right-radius: 5px;
  background-color: rgb(0, 51, 102);
  visibility: hidden;
  transform: perspective(200px) rotateY(90deg);
  transform-origin: 0 50% 0;
  transition: transform 0.3s 0.3s, visibility 0s 0.6s;
}

.info-hotspot.visible .info-hotspot-close-wrapper {
  visibility: visible;
  transform: perspective(200px) rotateY(0deg);
  transition: transform 0.3s,visibility 0s 0s;
}

.info-hotspot .info-hotspot-close-icon {
  width: 70%;
  height: 70%;
  margin: 15%;
}

.info-hotspot .info-hotspot-text {
  position: absolute;
  width: 300px;
  height: auto;
  max-height: 200px;
  top: 40px;
  left: 0;
  padding: 10px;
  background-color: rgb(0, 51, 102);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow-y: auto;
  visibility: hidden;
  
  transform: perspective(200px) rotateX(-89.999deg);
  transform-origin: 50% 0 0;
  transition: transform 0.3s, visibility 0s 0.3s;
}

.info-hotspot.visible .info-hotspot-text {
  visibility: visible;
  transform: perspective(200px) rotateX(0deg);
  transition: transform 0.3s 0.3s, visibility 0s 0s;
}


/*Medija query koji listu sa scenama prebacuje sa leve strance u donji deo i menja 
 flex direction kako bi se sve scene videle normalno, i kako ne bi dolazilo do 
 odsecanja
*/
@media only screen and (max-width: 1180px) {
  .sceneListElement {
    margin-left: 30px;
    margin-top: 5px;
  }

  .sceneListElements {
    display: flex;
    height: 100%;
  }

  .sceneList{
    height: 130px;
    width: 100%;
    bottom: 0;
  }
}
